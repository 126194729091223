.message-feed {
    width: calc(100% - 20px);
    flex: 0 1;
    padding-left: 10px;
    padding-right: 10px;
    align-self: center;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font-family);
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* Adding the ability to scroll */
}

.message-feed::-webkit-scrollbar {
    display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.message-feed {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.activity-group {
    outline: 5px solid #FF8A79;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
}

.activity-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-family: Inter-Bold;
    color: #333;
    text-align: left;
    align-items: baseline;
}

.activity-header img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media screen and (max-width: 800px) {
    .message-feed {
        height: 70vh;
    }
}