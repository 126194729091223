.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.25s;
}

.login-container h3 {
    font-size: 2rem;
    font-family: Inter-Regular;
    color: var(--font-color-main);
    margin-bottom: 20px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 500px;
    height: 400px;
    background-color: #242426;
    border-radius: var(--border-radius-big);
    padding: 20px;
    color: #F5F5F7;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.login-form input {
    width: 70%;
    height: 40px;
    border: none;
    color: #F5F5F7;
    outline: none;
    background-color: #242426;
    padding: 10px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    font-size: var(--fontsize-main);
    border-bottom: 1px solid #F5F5F7;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #242426 inset;
    /* Change the color to your own background color */
    -webkit-text-fill-color: #F5F5F7;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #242426 inset;
    /*your box-shadow*/
    -webkit-text-fill-color: #F5F5F7;
}

.login-form button {
    width: 70%;
    border-radius: 8px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    background-color: #242426;
    border-bottom: 1px solid #F5F5F7;
    color: #F5F5F7;
    font-family: Inter-Light;
    font-size: var(--fontsize-h2);
    cursor: pointer;
}

.login-error-message {
    position: absolute;
    top: 250px;
    color: #FF0000;
    font-size: var(--fontsize-main);
    font-family: Inter-Light;
}

.login-error-message p {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 800px) {
    .login-container {
        margin-top: 50px;
    }

    .login-error-message {
        top: 100px;
    }
}