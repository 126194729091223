.project-page-content-container {
  display: none;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.project-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

.project-page-header button {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-image: linear-gradient(to left, #F63030, #FFA904);
  color: var(--font-color-secondary);
  font-family: Inter-Bold;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  margin: 0;
}

.project-page-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.project-page-header-title h2 {
  font-size: 2rem;
  font-family: Inter-Semibold;
  color: var(--font-color-main);
  margin-bottom: 20px;
}

.project-page-header-title p {
  width: 60%;
  min-width: 300px;
  font-size: 1rem;
  text-align: left;
  font-family: Inter-Light;
  color: var(--font-color-main);
  margin: 0;
}

@media screen and (max-width: 800px) {
  .project-page-header {
    flex-direction: column-reverse;
  }

  .project-page-header-title {
    align-items: center;
  }

  .project-page-header-title p {
    text-align: center;
  }

}