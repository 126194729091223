.navbar-logo {
    height: 50px;
    pointer-events: none;
    transition: 0.25s;
}

.navbar-header {
    background-color: var(--background-main);
    height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontsize-navbar);
    font-family: Inter-Regular;
    color: var(--font-color-main);
}

.navbar-header a {
    cursor: pointer;
}

.navbar-profile-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.navbar-profile-logo {
    height: 25px;
    border-radius: 50%;
}

.login-btn {
    color: var(--font-color-main);
    text-decoration: none;
}

.logout-btn {
    color: var(--font-color-main);
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .navbar-logo {
        height: 30px;
    }
}