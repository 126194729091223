.message {
    text-align: left;
    align-self: flex-start;
    padding: 15px;
    margin-top: 10px;
    background-color: #FCF6ED;
    border-radius: 10px;
    width: auto;
    max-width: 80%;
}


.message-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1vw;
    word-break: break-word;
}

.message-sender-logo {
    align-self: self-start;
    height: 2rem;
}

.message-sender-name {
    font-size: var(--primary-font-size);
    font-weight: bold;
}

.message-content {
    font-size: var(--primary-font-size);
}