.dashboard-page-content-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  padding-bottom: 50px;
  transition: 0.25s;
}

.dashboard-page-content-container h1 {
  font-size: 3rem;
  font-family: Inter-Semibold;
  margin-bottom: 20px;
  background-image: linear-gradient(to right, var(--font-color-main), #b0b0b0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.dashboard-page-content-container button {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--btn-color-main);
  color: #F5F5F7;
  font-family: Inter-Bold;
  font-size: var(--fontsize-main);
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

/* this class is a container class that dhould display its content in a grid format */
.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-top: 20px;
}

/* this class is the project card in the grid */
.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 300px;
  height: 300px;
  background-color: var(--background-secondary);
  color: var(--font-color-secondary);
  border-radius: 28px;
  padding: 20px;
  text-align: left;
  cursor: pointer;
}


.project-card:hover {
  box-shadow: 0px 0px 5px 0px var(--background-secondary);
}

@media screen and (max-width: 1200px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .dashboard-page-content-container {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
  }

  .project-card {
    width: auto;
  }
}

.project-card h3 {
  font-size: var(--fontsize-h2);
  font-family: Inter-Semibold;
  margin-bottom: 10px;

}

.project-card p {
  font-size: var(--fontsize-main);
  font-family: Inter-Regular;
  margin-bottom: 10px;
}

.project-card-client-tag {
  position: relative;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: var(--background-tertiary);
  border-radius: 30px;
  padding: 5px;
}

.project-card-client-tag h4 {
  margin: 0;
}

.project-card-client-tag-circle {
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #F63030, #FFA904);
  width: 40px;
  height: 40px;
}