.chat-functions {
    flex: 1;
    overflow: auto;
    width: 428px;
    min-height: 770px;
    max-height: 770px;
    background-color: #ffff;
    color: var(--secondary-text);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 30px;
    border: 10px solid var(--background-secondary);
    z-index: 2;
}


@media screen and (max-width: 800px) {
    .chat-functions {
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
    }
}