.preview-container {
    width: 100%;
    height: 100%;
    background-color: var(--tertiary-divs);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.preview-container h1 {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font);
    font-size: var(--primary-font-h1);
    color: var(--secondary-text);
}

.preview-container p {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font);
    font-size: var(--primary-font-size);
    color: var(--tertiary-text);
}