.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    padding-left: 20px;
    padding-right: 20px;
}

.profile-content-container {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    width: 500px;
    height: auto;
    align-items: left;
    justify-content: center;
    padding: 20px;
    transition: 0.25s;
    border-radius: var(--border-radius-big);
    border: 2px solid var(--background-secondary);
}

.profile-content-container h2 {
    font-size: 2rem;
    font-family: Inter-Semibold;
    color: var(--font-color-main);
    margin-bottom: 20px;
    margin-top: 0px;
    text-align: left;
}

.profile-content-container label {
    font-size: 1.5rem;
    font-family: Inter-Regular;
    color: var(--font-color-main);
    margin-bottom: 10px;
    text-align: left;
}

.profile-content-container p {
    width: 70%;
    min-width: 300px;
    font-size: 1rem;
    text-align: left;
    font-family: Inter-Light;
    color: var(--font-color-main);
    margin-top: 0px;
}

@media screen and (max-width: 800px) {
    .profile-container {
        margin-top: 50px;
    }

    .profile-content-container {
        margin-top: 0;
    }
}