.App {
  text-align: center;
  align-self: center;
  background-color: var(--background-main);
  height: 100vh;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.main-page-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 70vw;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.main-page-content-container h1 {
  font-size: 3rem;
  font-family: Inter-Semibold;
  margin-bottom: 20px;
  background-image: linear-gradient(to right, var(--font-color-main), #b0b0b0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.main-page-content-container button {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-image: linear-gradient(to left, #F63030, #FFA904);
  color: var(--font-color-secondary);
  font-family: Inter-Bold;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .main-page-content-container {
    margin-top: 50px;
  }
}