.chat-input-container {
    flex: 1 0;
    width: 100%;
    background-color: var(--tertiary-divs);
    color: var(--primary-text);
    overflow: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 1vh;
    padding-top: 1vh;
    flex-grow: initial;
}

.chat-input {
    background-color: rgb(255, 255, 255);
    color: var(--primary-text);
    border: 2px solid var(--primary-highlight);
    border-radius: 0.5rem;
    padding: 0px 10px 0px 10px;
    font-size: var(--primary-font-size);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.chat-input-attachment {
    justify-items: center;
}

.text-input {
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: var(--primary-text);
    border: none;
    padding: 10px;
    font-size: var(--primary-font-size);
    resize: none;
    height: auto;
    font-family: var(--primary-font-family);
    overflow-y: scroll;
    border: 2px solid var(--background-secondary);
    opacity: 0.7;
    border-radius: 20px;
    max-height: 200px;
}

.text-input::-webkit-scrollbar {
    display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.text-input {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.text-input:focus {
    outline: none;
}


.file-attachement {
    font-size: 2rem;
    background-color: var(--tertiary-divs);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-content: center;
}

.paper_clip {
    width: 2rem;
    opacity: 0.7;
}

.paper_clip:hover {
    opacity: 0.9;
}

.send-button {
    font-size: 30px;
    background-color: var(--tertiary-divs);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-content: center;
}

.send-logo {
    height: 30px;
    opacity: 0.7;
}

.send-logo:hover {
    opacity: 0.9;
}

.chat-mc-input {
    background-color: rgb(255, 255, 255);
    color: var(--primary-text);
    border: 2px solid var(--primary-highlight);
    border-radius: 0.5rem;
    padding: 10px 40px 10px 40px;
    font-size: var(--primary-font-size);
    gap: 0.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mc-option {
    display: flex;
    flex-direction: column;
    min-width: 50px;
    padding: 8px 6px 8px 6px;
    font-family: Inter-Medium;
    font-size: var(--primary-font-size);
    color: rgb(255, 138, 121);
    border: 2px solid rgb(255, 138, 121);
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
}

.mc-option:hover {
    /*add bacjground box shdow*/
    box-shadow: 0px 0px 1px 0px var(--btn-color-secondary);
    cursor: pointer;
}