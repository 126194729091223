:root {
  --fontsize-navbar: 1rem;
  --fontsize-h1: 1rem;
  --fontsize-h2: 1.5rem;
  --fontsize-main: 1rem;
  --font-color-main: #F0F0F0;
  --font-color-secondary: #FAFAFA;
  --background-main: #0F0F0F;
  --background-secondary: #242426;
  --background-tertiary: #47474B;
  --btn-color-main: #1D1D1F;
  --btn-color-secondary: #FF8A79;
  --border-radius-big: 28px;
  ;
}

@font-face {
  font-family: Inter-Black;
  src: url(fonts/Inter-Black.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(fonts/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(fonts/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Thin;
  src: url(fonts/Inter-Thin.ttf);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}